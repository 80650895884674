import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useReducer, useState } from "react";

import { ITimeEntry, ETimesheetEntryStatus, ETimesheetEntryType } from "../interfaces/timeEntry";
import {
    ChangeStatus,
    EBookingStatus,
    ECalendarStatus,
    ESystemStatus,
    EWorkWorderType,
    IBookableResourceBooking,
    IEvent,
    IEventBase,
    IOtherSiteEvent
} from "../interfaces/event";
import {
    addDays,
    addMinutes,
    differenceInDays,
    differenceInMinutes, endOfDay,
    endOfWeek,
    format,
    isAfter,
    isBefore,
    isEqual,
    isSameDay, areIntervalsOverlapping,
    isWithinInterval, startOfDay,
    startOfWeek, subDays, subMinutes
} from "date-fns";
import { IProviderProps } from "../interfaces/provider";
import { apiContext } from "./api";
import { otherSiteShiftFormatter, shiftsFormatter } from "../helpers/shifts/shiftsFormatter";
import Swal from "sweetalert2";
import { useTheme } from "@fluentui/react";
import { useIncidentTypes } from "../hooks/incidentType";
import { resourcesContext } from "./resources";
import { EEventStatus } from "../helpers/calendar";
import { toast } from "../helpers/notifications";
import {
    IAvailability,
    IAvantCareEnvironmentInfo,
    IBookableResourceRosterValidationResponse,
    IBookingStatusResponse,
    IDraftSavedItem,
    ILinkedError,
    IOtherSiteWorkOrder,
    IPayrollHourVariation,
    IPayrollHourVariationResponse,
    IPublishShiftsRequestViewModel,
    IResourceData,
    IRosteringBookingViewModel,
    IRosterValidationResponse,
    IServiceResponse,
    ISiteLocation,
    IWorkOrder,
    IWorkOrderType,
    RosterValidationErrorType,
    ShiftCancellationRequestStatus,
    JobType,
    statecode
} from "../interfaces/resource";
import { IIncidentType } from "../interfaces/incidentTypes";
import { TView } from "../components/scheduler/Scheduler";
import { authContext } from "./auth";
import { IDraft } from "../interfaces/draft";
import { IAvantCareConfigurationModel } from "../interfaces/avantcareConfig";

export enum DraftStatus {
    pending,
    synced,
    edited,
    empty,
    publishing,
    found,
}

export enum EPublishStatus {
    cancelled = 'cancelled',
    no_shift = 'noshift',
    network_error = 'networkerror',
    publish_error = 'publisherror',
    error = 'error',
    success = 'success',
}

export enum EEventToDBMap {
    isJobBidding = 'illumina_openforshiftjobbidding',
    start = 'msdyn_timewindowstart',
    end = 'msdyn_timewindowend',
    description = 'msdyn_workordersummary',
    shiftLength = 'msdyn_totalestimatedduration',
    bookableResourceStatus = 'BookingStatus@odata.bind',
    status = 'msdyn_systemstatus',
}

interface IShiftContext {
    loader: { loading: boolean, message: string }
    getShiftsForCalendarView: () => IEvent[]
    getOtherSiteShiftsForCalendarView: () => IOtherSiteEvent[]
    showOtherSiteShifts: boolean
    toggleShowOtherSiteShifts: () => void
    setCalendarDates: (startDate: Date, type: TView) => void
    startDate: Date,
    endDate: Date,
    moveShift: (shiftId: string, toResourceId: string | undefined, skipHistory?: boolean | undefined) => Promise<boolean>
    getShift: (id: string) => IEvent | undefined
    draftStatus: DraftStatus,
    getResourceData: (id: string) => IResourceData
    checkShiftLocationIsValid: (startDate: Date, endDate: Date, eventId?: string, resourceId?: string, originalResourceId?: string) => EEventStatus
    updateShiftInfo: (shiftId: string, data: Partial<IEvent>) => void

    loadDraft: () => void
    saveDraft: () => void
    deleteDraft: () => void

    validateAndPublishShifts: () => void;
    validateAllShifts: () => void;
    publishShift: (shiftId: string) => Promise<any>;
    undoShiftMove: () => void
    reloadShifts: () => void
    removeShift: (shiftId: string) => void;

    calculateCost: () => void;
    checkRequiredCharacteristics: (shiftId: string, resourceId: string) => boolean
    defineUserAvailability: (dateStart: Date, dateEnd: Date, cResourceId: string, eventId: string) => IAvailability[]
    checkIsModified: (preferredResourceId: string, resourceId: string) => ChangeStatus
    getResourceShifts: (resourceId: string) => IEvent[];
    getEnvironmentInfo: () => IAvantCareEnvironmentInfo;
    checkIfShiftAllowed: (event: IEvent) => void;
    publishSingleShift: (event?: IEvent) => Promise<{ status: EPublishStatus, updatedEvent?: Partial<IEvent> }>;
    patchShift: (shiftId: string, shiftData: Partial<IEvent>) => Promise<any>;
    createLateClientCancelTimeEntry: (shift: IEvent) => Promise<any>;
    approveRejectCancellationRequest: (event: IEvent, approved: boolean, comments: string) => Promise<boolean>;
    view: TView
    setView: (view: TView) => void
    loadingPayrollData: boolean
    siteLocation?: string
    validated: boolean,
    getShiftTypes: () => IWorkOrderType[];
    getBookingStatusList: () => Promise<IBookingStatusResponse[]>;
    isCancellationReviewPending: (shift: IEvent | undefined) => boolean;
}

interface IHistoryStore {
    eventId: string
    resourceId: string
    fromResourceID: string
    calendarStatus: ECalendarStatus
}

const shiftsContext = createContext<IShiftContext>({
    loader: { loading: false, message: '' },
    draftStatus: DraftStatus.empty,
    getShiftsForCalendarView: () => ([]),
    getOtherSiteShiftsForCalendarView: () => ([]),
    showOtherSiteShifts: false,
    toggleShowOtherSiteShifts: () => {
    },
    startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    setCalendarDates: (startDate: Date, type) => {
    },
    moveShift: async () => false,
    getShift: (id: string) => {
        return undefined
    },
    updateShiftInfo: (shiftId: string, data: Partial<IEvent>) => {
    },
    getResourceData: () => { return null as any; },
    getResourceShifts: (resourceId: string) => {
        return [];
    },
    checkShiftLocationIsValid: () => 2,
    loadDraft: () => {
    },
    undoShiftMove: () => {
    },
    removeShift: (shiftId: string) => { },
    saveDraft: () => {
    },
    validateAndPublishShifts: () => {
    },
    validateAllShifts: () => { },
    deleteDraft: () => {
    },
    reloadShifts: () => {
    },
    checkRequiredCharacteristics: () => false,
    defineUserAvailability: () => [],
    checkIsModified: () => 0,
    calculateCost: () => {
    },
    getEnvironmentInfo: () => null as any,
    checkIfShiftAllowed: () => {
    },
    publishShift: (shiftId: string) => {
        return null as any;
    },
    publishSingleShift: async (event?: IEvent) => ({ status: EPublishStatus.error }),
    patchShift: async (shiftId: string, shiftData: Partial<IEvent>) => {
    },
    createLateClientCancelTimeEntry: async (shift: IEvent) => { },
    view: "resourceTimelineFortnight",
    setView: () => {
    },
    loadingPayrollData: false,
    validated: false,
    getShiftTypes: () => { return []; },
    approveRejectCancellationRequest: async (event: IEvent, approved: boolean, comments: string) => { return true; },
    getBookingStatusList: () => { return null as any; },
    isCancellationReviewPending: (shift: IEvent | undefined) => { return false; },
})

type EventsArrayType = IEvent[]
type EventsObjectType = { [key: string]: IEvent }

type ShiftStoreReducerSetActionType = { set: EventsArrayType | EventsObjectType, update?: never, delete?: never }
type ShiftStoreReducerUpdateActionType = { set?: never, update: EventsArrayType | EventsObjectType, delete?: never }
type ShiftStoreReducerDeleteActionType = { set?: never, update?: never, delete: string[] }

type ShiftStoreReducerActionType = ShiftStoreReducerSetActionType | ShiftStoreReducerUpdateActionType | ShiftStoreReducerDeleteActionType

function ShiftStoreReducerSet(previousState: IEvent[], events: EventsArrayType | EventsObjectType) {
    if (Array.isArray(events)) {
        return events;
    } else if (typeof events === "object") {
        return Object.values(events);
    }
    return previousState;
}

function ShiftStoreReducerUpdate(previousState: IEvent[], events: EventsArrayType | EventsObjectType) {
    const newState = [...previousState]

    if (Array.isArray(events)) {
        events.forEach(event => {
            const index = newState.findIndex(x => x.id === event.id)
            if (index > -1) {
                newState[index] = event
            } else {
                newState.push(event)
            }
        })
    } else {
        Object.keys(events).forEach(key => {
            const index = newState.findIndex(x => x.id === key)
            if (index > -1) {
                newState[index] = (events as EventsObjectType)[key]
            } else {
                newState.push((events as EventsObjectType)[key])
            }
        })
    }

    return newState
}

function ShiftStoreReducerDelete(previousState: IEvent[], ids: string[]) {
    return previousState.filter(x => !ids.includes(x.id))
}

function ShiftStoreReducer(previousState: IEvent[], data: ShiftStoreReducerActionType) {
    if (!data) {
        return previousState;
    }

    if (data.set) {
        return ShiftStoreReducerSet(previousState, data.set);
    }

    if (data.update) {
        return ShiftStoreReducerUpdate(previousState, data.update);
    }

    if (data.delete) {
        return ShiftStoreReducerDelete(previousState, data.delete);
    }

    return previousState;
}


const ShiftsProvider = (props: IProviderProps) => {
    let initialAvantCareEnvInfo: IAvantCareEnvironmentInfo; //Need the envinfo on page load before setAvantCareEnvInfo value is available
    const theme = useTheme()
    const [cStartDate, setCStartDate] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
    const [cEndDate, setCEndDate] = useState<Date>(endOfWeek(new Date(), { weekStartsOn: 1 }))
    const [view, setView] = useState<TView>('resourceTimelineFortnight');

    const [loadedDates, setLoadedDates] = useState<boolean>(false)

    const [shiftStore, setShiftStore] = useReducer(ShiftStoreReducer, [])
    const shiftStoreRef = useRef(shiftStore);
    const [shiftStoreAccess, setShiftStoreAccess] = useState<{ [key: string]: IEvent }>({})
    const [otherSiteShiftStore, setOtherSiteShiftStore] = useState<IOtherSiteEvent[]>([]);
    const [allShiftTypes, setAllShiftTypes] = useState([]);
    const allShiftTypesRef = useRef(allShiftTypes);
    const [showOtherSiteShifts, setShowOtherSiteShifts] = useState(false)

    const [loader, setLoading] = useState<{ loading: boolean, message: string }>({ loading: false, message: '' })
    const [avantCareEnvInfo, setAvantCareEnvInfo] = useState<IAvantCareEnvironmentInfo>()
    const [history, setHistory] = useState<IHistoryStore[]>([])
    const [draftStatus, setDraftStatus] = useState<DraftStatus>(DraftStatus.empty)
    const [draftId, setDraftId] = useState<string | undefined>()
    const [incidentTypes, reloadIncidentTypes] = useIncidentTypes([]);
    const allIncidentTypesRef = useRef(incidentTypes);
    const { get, post, patch, remove } = useContext(apiContext)
    const [validateHack, setValidationHack] = useState(true)
    //const params = useParams<TUrlParams>()
    const { getResource, getAllResources, getLeaves } = useContext(resourcesContext)
    const [reloadView, setReloadView] = useState(false)
    const { getAccount } = useContext(authContext)
    const [payrollNeedsUpdate, setPayrollNeedsUpdate] = useState(false);
    const [loadingPayrollData, setLoadingPayrollData] = useState(false);
    const [siteLocation, setSiteLocation] = useState<string>();
    const [payrollHoursVariations, setPayrollHoursVariations] = useState<IPayrollHourVariation[]>();
    const [validated, setValidated] = useState(false);
    const [bookingStatusList, setBookingStatusList] = useState<IBookingStatusResponse[]>(null as any);

    const ServiceSiteLocationType = 390950001; //Site location type in Work Order/Shift

    useEffect(() => {
        allIncidentTypesRef.current = incidentTypes;
    }, [incidentTypes])

    useEffect(() => {
        allShiftTypesRef.current = allShiftTypes;
    }, [allShiftTypes])

    useEffect(() => {
        if (!loader.loading && payrollNeedsUpdate && !loadingPayrollData) {
            getPayrollData()
        }
    }, [payrollNeedsUpdate, loader])

    useEffect(() => {
        const newShiftStoreAccess: { [key: string]: IEvent } = {};
        shiftStore.forEach(shift => newShiftStoreAccess[shift.id] = shift)
        setShiftStoreAccess(newShiftStoreAccess)
    }, [shiftStore])

    useEffect(() => {
        const loadPayrollCycle = () => {
            const localPayrollData = localStorage.getItem(`illuminance-scheduler-payroll-data_${getSiteId()}`);

            if (!localPayrollData) {
                getPayrollData();
                return;
            }
            const payrollData = JSON.parse(localPayrollData) as { start: Date, end: Date } | undefined;
            const hasPayrollData = payrollData && payrollData['start'] && payrollData['end'];
            if (!hasPayrollData) return;
            let payrollStart = new Date()
            let payrollEnd = new Date();
            const psd = new Date(payrollData['start']);
            const ped = new Date(payrollData['end']);
            const payrollLength = differenceInDays(ped, psd);
            if (isWithinInterval(payrollStart, { start: psd, end: ped })) {
                payrollStart = psd;
                payrollEnd = ped;
            }
            else {

                const diffToNow = differenceInDays(new Date(), new Date(payrollData['start']))
                const daysSinceStartOfPayroll = diffToNow % payrollLength;

                payrollStart.setDate(payrollStart.getDate() - daysSinceStartOfPayroll);
                payrollStart = startOfDay(payrollStart);

                payrollEnd.setTime(payrollStart.getTime());
                payrollEnd.setDate(payrollEnd.getDate() + payrollLength);
                payrollEnd = endOfDay(payrollEnd);
            }

            setCStartDate(payrollStart)
            setCEndDate(payrollEnd)
            setLoadedDates(true)
            setView(payrollLength > 7 ? 'resourceTimelineFortnight' : 'resourceTimelineWeek')

            setPayrollNeedsUpdate(true);

        }
        loadPayrollCycle()
    }, [])

    //Triggered when a new job added in parent iframe and a postMessage is sent
    useEffect(() => {
        window.addEventListener('message', (e: any) => {
            if (!e.data?.type || e.data.type != 'ac-rb') return;
            if (e.data?.jobId != undefined && e.data?.jobId != '') {
                let jobId = e.data.jobId.replace(/[{}]/g, '') //remove '{}' from id

            }
        })
    }, [])


    useEffect(() => {
        const checkForDraft = async () => {
            const account = getAccount();
            const siteId = getSiteId();
            const res = await get(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts`)
            const draftList: IDraft[] = res.body
            const calDraft = draftList.find(draft => {
                const startDate = new Date(draft.start)
                return format(startDate, 'yyyy-MM-dd') === format(cStartDate, 'yyyy-MM-dd')
            })
            if (calDraft) {
                setDraftId(calDraft.id)
                setDraftStatus(DraftStatus.found)
            } else {
                setDraftStatus(DraftStatus.empty)
            }
        }
        if (loadedDates) {
            checkForDraft()
        }
    }, [loadedDates, cStartDate, cEndDate])

    useEffect(() => {
        if ((incidentTypes.length > 0 || !shiftStore || shiftStore.length < 1) && loadedDates) {
            loadAvantCareEnvironment();
            loadShifts();
            loadSiteLocation();
        }
    }, [loadedDates])

    useEffect(() => {
        if (reloadView) {
            setReloadView(false)
        }
    }, [reloadView])

    const getPayrollData = async () => {
        setLoadingPayrollData(true);
        const startDate = format(cStartDate, 'yyyy-MM-dd')
        const endDate = format(cEndDate, 'yyyy-MM-dd')

        const res = await get(
            `/rostering/${getSiteId()}/payrollcycles?start=${startDate}&end=${endDate}`,
        ) as { body?: { startDate: string, endDate: string }[] }

        if (!Array.isArray(res.body)) {
            setLoadingPayrollData(false)
            return;
        }
        const today = new Date();
        let payroll = res.body.find(payrollPeriod => isWithinInterval(today, {
            start: new Date(payrollPeriod.startDate),
            end: endOfDay(new Date(payrollPeriod.endDate))
        }));

        if (!payroll) {
            setLoadingPayrollData(false)
            return;
        }

        const payrollLength = differenceInDays(new Date(payroll.endDate), new Date(payroll.startDate))
        const payrollStartToNowDiff = differenceInDays(new Date(), new Date(payroll.startDate))
        const startOfCurrentPayroll = payrollStartToNowDiff % 14

        let payrollStart = new Date();
        payrollStart.setDate(payrollStart.getDate() - startOfCurrentPayroll)
        payrollStart = startOfDay(payrollStart);

        let payrollEnd = new Date();
        payrollEnd.setTime(payrollStart.getTime());
        payrollEnd.setDate(payrollEnd.getDate() + payrollLength);
        payrollEnd = endOfDay(payrollEnd);

        const diff = payrollLength
        setCStartDate(payrollStart)
        setCEndDate(payrollEnd)
        setLoadedDates(true)
        setView(diff > 7 ? 'resourceTimelineFortnight' : 'resourceTimelineWeek')

        localStorage.setItem(`illuminance-scheduler-payroll-data_${getSiteId()}`, JSON.stringify({
            start: payrollStart,
            end: payrollEnd
        }))
        setPayrollNeedsUpdate(false);
        setLoadingPayrollData(false)
    }

    const loadAvantCareEnvironment = async () => {
        const envInfo = await get('/AvantCare/environment') as IAvantCareEnvironmentInfo;
        setAvantCareEnvInfo(envInfo);
        initialAvantCareEnvInfo = envInfo;
    }
    const loadSiteLocation = async () => {
        const locationID = getSiteId();
        const siteLocation = await get(`/Entity/illumina_sitelocations/${locationID}`, { select: 'illumina_name' }) as ISiteLocation;
        document.title = `Roster board - ${siteLocation.illumina_name}`;
        setSiteLocation(siteLocation?.illumina_name)
    }

    const getEnvironmentInfo = () => {
        return avantCareEnvInfo as IAvantCareEnvironmentInfo;
    }
    const getSiteId = () => {
        const siteId = localStorage.getItem('siteid');
        const locationID = siteId ?? "c6fe3919-fb8e-ec11-b400-0022489242ae"
        return locationID;
    }
    const getShiftTypes = () => {
        return allShiftTypes;
    };

    const getBookingStatusList = async (): Promise<IBookingStatusResponse[]> => {
        if (bookingStatusList == null) {
            var r = await get('/Entity/bookingstatuses', {
                filter: 'statecode eq 0'
            }) as { value: IBookingStatusResponse[] };
            setBookingStatusList(r.value);
            return r.value;
        }
        return bookingStatusList;
    }

    const CurrentSiteJobSelectQuery = 'msdyn_name,msdyn_systemstatus,msdyn_timewindowstart,msdyn_timewindowend,msdyn_timefrompromised,msdyn_timetopromised,_msdyn_preferredresource_value,msdyn_workordersummary,msdyn_bookingsummary,msdyn_serviceaccount,msdyn_instructions,illumina_jobtype,msdyn_msdyn_workorder_msdyn_requirementcharacteristic_WorkOrder,illumina_openforshiftjobbidding, msdyn_totalestimatedduration,_msdyn_workordertype_value';
    const CurrentSiteJobExpandQuery = `msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder($select=name,_bookingstatus_value,_resource_value,modifiedon,illumina_cancellationrequeststatus,illumina_cancellationrequestcomments),msdyn_serviceaccount($select=name,accountnumber,address1_composite),msdyn_primaryincidenttype($select=msdyn_name,msdyn_incidenttypeid),msdyn_workordertype($select=illumina_displaycolour,msdyn_name,msdyn_workordertypeid)`;

    const getShiftById = async (shiftId: string) => {
        const locationID = getSiteId();
        const response = await get(`/Entity/msdyn_workorders`, {
            select: CurrentSiteJobSelectQuery,
            expand: CurrentSiteJobExpandQuery,
            filter: `illumina_servicelocation eq ${ServiceSiteLocationType} and msdyn_systemstatus ne ${ESystemStatus.Canceled} and _illumina_sitelocationfromid_value eq ${locationID} and msdyn_workorderid eq '${shiftId}'`
        });
        let shifts = response.value as IWorkOrder[]

        return (shifts?.length > 0) ? shifts[0] : null;

    }
    const loadShifts = async (syncHistory?: boolean, startData: Date = cStartDate, endData: Date = cEndDate) => {
        setValidated(false)
        setLoading({ loading: true, message: syncHistory ? 'Reloading Shifts' : 'Loading Shifts' });
        let apiIncidentTypes: IIncidentType[] = []
        if (syncHistory || (!incidentTypes || incidentTypes.length < 1)) {
            apiIncidentTypes = await reloadIncidentTypes()
        }

        //extra day for timezone issue. This will load extra shifts which will be excluded later with local date filter
        var sdStr = format(subDays(startData, 1), 'yyyy-MM-dd');
        var edStr = format(addDays(endData, 1), 'yyyy-MM-dd');
        var edEndOfTheDay = endOfDay(endData);

        const locationID = getSiteId();

        const shiftTypesPromise = get('/Entity/msdyn_workordertypes', {
            select: 'msdyn_name,illumina_displaycolour',
            filter: 'illumina_displaycolour ne null and statecode eq 0'
        })
        const shiftsListPromise = get(
            '/Entity/msdyn_workorders',
            {
                select: CurrentSiteJobSelectQuery,
                filter: `illumina_servicelocation eq ${ServiceSiteLocationType} and msdyn_systemstatus ne ${ESystemStatus.Canceled} and _illumina_sitelocationfromid_value eq ${locationID} and (msdyn_timewindowstart ge ${sdStr} and msdyn_timewindowstart le ${edStr})`,
                expand: CurrentSiteJobExpandQuery,
                top:5000
            }
        );
        const shiftsOnOtherSitesPromise = get(
            '/Entity/msdyn_workorders',
            {
                select: 'msdyn_name,msdyn_timewindowstart,msdyn_timewindowend,msdyn_totalestimatedduration,msdyn_timefrompromised,msdyn_timetopromised,_msdyn_workordertype_value,illumina_jobtype,_illumina_sitelocationfromid_value',
                filter: `msdyn_systemstatus ne ${ESystemStatus.Canceled} and _illumina_sitelocationfromid_value ne ${locationID} and (msdyn_timewindowstart ge ${sdStr} and msdyn_timewindowstart le ${edStr})`,
                expand: 'msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder($select=name,_bookingstatus_value,_resource_value),msdyn_workordertype($select=illumina_displaycolour,msdyn_name,msdyn_workordertypeid),illumina_ServiceContact($select=fullname)',
                top:5000
            }
        )
        let dateFilter = `(illumina_payrollcycle/illumina_startdate ge '${sdStr}' and illumina_payrollcycle/illumina_startdate le '${edStr}') ` +
            `or (illumina_payrollcycle/illumina_enddate ge '${sdStr}' and illumina_payrollcycle/illumina_enddate le '${edStr}') ` +
            ` or (illumina_payrollcycle/illumina_startdate le ${sdStr} and illumina_payrollcycle/illumina_enddate ge ${edStr})`;

        const payrollHoursVariationsPromise = get('/Entity/illumina_payrollhourvariations', {
            filter: dateFilter,
            select: 'illumina_minimumnoofworkhoursperpayroll,illumina_maximumnoofworkhoursperpayroll,_illumina_payrollcycle_value,_illumina_bookableresource_value',
            expand: 'illumina_payrollcycle($select=illumina_startdate,illumina_enddate)'
        })

        const shiftsList = await shiftsListPromise;
        const shiftsOnOtherSites = await shiftsOnOtherSitesPromise;
        const payrollHoursVariations = await payrollHoursVariationsPromise;
        const shiftTypes = await shiftTypesPromise;
        setAllShiftTypes(shiftTypes?.value);
        if (payrollHoursVariations?.value) {
            setPayrollHoursVariations(payrollHoursVariations.value.map((x: IPayrollHourVariationResponse) => {
                return {
                    bookableResourceId: x._illumina_bookableresource_value,
                    maximumHoursPerPayroll: x.illumina_maximumnoofworkhoursperpayroll,
                    minimumHoursPerPayroll: x.illumina_minimumnoofworkhoursperpayroll,
                    payrollCycleId: x._illumina_payrollcycle_value,
                    payrollEnd: new Date(x.illumina_payrollcycle.illumina_enddate),
                    payrollStart: new Date(x.illumina_payrollcycle.illumina_startdate)
                } as IPayrollHourVariation;
            }));
        }

        const workOrders = shiftsList.value as IWorkOrder[];
        if (workOrders) {
            const sanitizedList = workOrders.map((shift: any) => shiftsFormatter(shift, shiftTypes?.value, apiIncidentTypes.length < 1 ? incidentTypes : apiIncidentTypes))
                .filter(shift => shift?.start && shift.start >= startData && shift.start <= edEndOfTheDay) as IEvent[]; //exclude any extra items outside of payroll cycle
            if (syncHistory && (history && history.length > 0)) {
                const syncedShifts = syncHistoryToState(sanitizedList, history);
                validateShifts(syncedShifts).then(res => {
                    setShiftStore({ update: res });

                    setLoading({ loading: false, message: '' });
                })
            } else {
                validateShifts(sanitizedList).then(res => {
                    setShiftStore({ update: res })

                    setLoading({ loading: false, message: '' });
                })
            }
        }

        const otherSiteWorkOrders = shiftsOnOtherSites.value as IOtherSiteWorkOrder[]
        if (otherSiteWorkOrders) {
            const sanitizedList = otherSiteWorkOrders.map((shift) => otherSiteShiftFormatter(shift, shiftTypes?.value))
                .filter(shift => shift != undefined && shift?.start && shift.start >= startData && shift.start <= edEndOfTheDay) as IOtherSiteEvent[] //exclude any extra items outside of payroll cycle

            if (sanitizedList.length > 0) {
                setOtherSiteShiftStore(sanitizedList)
            }
        }

        setLoading({ loading: false, message: '' });

    }

    const moveShift = async (shiftId: string, toResourceId?: string, skipHistory?: boolean) => {
        let promise: Promise<void> | undefined = undefined;
        if (!shiftStore) {
            return false
        }
        const cpShifts = [...shiftStore]
        const index = cpShifts.findIndex(sh => sh.id === shiftId)
        if (index < 0) {
            return false
        }
        if (toResourceId) {
            const newShift = { ...cpShifts[index] };
            const oldResourceId = newShift.resourceId;
            const hasRequiredCharacteristics = checkRequiredCharacteristics(shiftId, toResourceId)
            const modifiedStatus = checkIsModified(newShift.originalResourceID, toResourceId)
            const oldCalendarStatus = newShift.calendarStatus

            newShift.resourceId = toResourceId

            newShift.changeStatus = modifiedStatus
            newShift.loading = toResourceId !== '0'

            if (!hasRequiredCharacteristics) {
                newShift.calendarStatus = ECalendarStatus.issue
            } else if (toResourceId === '0') {
                newShift.calendarStatus = ECalendarStatus.scheduled
            } else {
                newShift.calendarStatus = ECalendarStatus.assigned
            }
            if (toResourceId === '0') {
                newShift.calendarStatus = ECalendarStatus.scheduled
            }
            if (skipHistory == null || skipHistory == false)
                setHistory((prev) => {
                    return [...prev, {
                        resourceId: toResourceId,
                        eventId: shiftId,
                        fromResourceID: oldResourceId ?? "0",
                        calendarStatus: oldCalendarStatus
                    }]
                })
            setDraftStatus(DraftStatus.edited)

            if (toResourceId !== '0') {
                promise = checkIfShiftMoveAllowed(newShift)
            }

            setShiftStore({ update: [newShift] })
        }

        if (promise) {
            await promise;
        }

        return true
    }

    const validateShifts = async (shifts: IEvent[]): Promise<{ [key: string]: IEvent }> => {
        const cpShiftStore = [...shifts]
        let assignedShifts: IRosteringBookingViewModel[] = [];
        let updatedShifts: { [key: string]: IEvent } = {};
        let originalShifts: { [key: string]: IEvent } = {};
        let newShiftStore: { [key: string]: IEvent } = {};

        cpShiftStore.forEach(shift => {
            if (shift.resourceId && shift.resourceId != '' && shift.resourceId != '0') {
                assignedShifts.push({
                    id: shift.id,
                    jobId: shift.id,
                    tempId: '',
                    start: shift.start,
                    end: shift.end,
                    bookableResourceId: shift.resourceId,
                    cost: 0,
                    status: shift.changeStatus,
                    bookingId: shift.BookingId
                })
                updatedShifts[shift.id] = {
                    ...shift,
                    validating: true
                }
                originalShifts[shift.id] = shift;
                return;
            }
            updatedShifts[shift.id] = shift;
        });

        setShiftStore({ update: updatedShifts })

        if (assignedShifts.length == 0) {
            setValidated(true)
            return newShiftStore
        }

        setLoading({ loading: true, message: 'Validating Shifts' });
        const response = (await post(`/Rostering/validate`, {
            shifts: assignedShifts,
            siteId: localStorage.getItem('siteid')
        })) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;
        if (!response.success) {
            if (response.errors && response.errors[0]) {
                await toast('error', response.errors[0]);
            }

            setLoading({ loading: false, message: '' });

            return originalShifts
        }
        response.body.forEach(returnedShift => {
            if (originalShifts[returnedShift.id]) {
                newShiftStore[returnedShift.id] = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
            } else {
                const newShift = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
                newShiftStore[newShift.id] = newShift;
            }

        });

        setLoading({ loading: false, message: '' });
        return newShiftStore
    }

    const isCancellationReviewPending = (shift: IEvent | undefined) => {
        return (shift && shift.isCancellationRequestPendingOriginal && shift.resourceId != '0' && shift.resourceId == shift.originalResourceID) as boolean;
    }

    const checkIfShiftMoveAllowed = async (shift: IEvent) => {
        const deletedShifts = shiftStore.filter(x => x.id != shift.id && shift.resourceId != x.originalResourceID && shift.resourceId == x?.originalResourceID).map(x => {
            return { jobId: x.id, start: x.start, end: x.end, status: ChangeStatus.Deleted }
        });

        const newShifts = shiftStore.filter(x => x.id != shift.id && x.resourceId == shift.resourceId && x.resourceId != x?.originalResourceID).map(x => {
            return { jobId: x.id, start: x.start, end: x.end, status: ChangeStatus.Modified }
        });
        let allChangedShifts = newShifts.concat(deletedShifts);

        await post(`/Rostering/bookableresource/${shift.resourceId}/isshiftAllowed?jobId=${shift?.id}`, allChangedShifts)
            .then((response: IServiceResponse<IRosterValidationResponse>) => {
                if (response.success && response.body.status == 'Failed') {
                    toast('error', response.body.errors[0].message);
                }
                const validatedShift = populateCalendarStatusBasedOnServiceResponse(shift, response.body.errors, shift.resourceId);
                validatedShift.loading = false
                setShiftStore({ update: [validatedShift] })
            });
    }

    const getCalendarStatus = (shift: IEvent) => {
        if (shift.resourceId == '0') return ECalendarStatus.scheduled;
        const hasRequiredCharacteristics = checkRequiredCharacteristics(shift.id, shift.resourceId);
        return hasRequiredCharacteristics ? ECalendarStatus.assigned : ECalendarStatus.issue;
    }

    const populateCalendarStatusBasedOnServiceResponse = (shift: IEvent, errors: ILinkedError[], targetResourceId: string | undefined): IEvent => {
        const cpShift = { ...shift, exceededMaxWorkingHours: false, unavailable: false, missingSkills: false, isOverTime: false }
        if (shift.resourceId != targetResourceId) {
            return shift;
        }
        let envInfo = initialAvantCareEnvInfo || avantCareEnvInfo;
        const shiftErrorMessages: string[] = []
        var hasError = false;
        let errorValidationTypes = envInfo?.configuration.rosterBoardModule?.showHardAlertsOnlyFor;
        if (!errorValidationTypes) {
            errorValidationTypes = [RosterValidationErrorType.ExceededMaxWorkingHours, RosterValidationErrorType.ConflictBooking, RosterValidationErrorType.Unavailable, RosterValidationErrorType.Unknown];
        }
        //if resource id and target resource id doesn't match, it means the shift has been moved out of target resrouce since the FE was waiting for service call to finish
        errors.forEach((error) => {
            if (errorValidationTypes?.find(v => error.errorType == v)) {
                cpShift.calendarStatus = ECalendarStatus.error;
                hasError = true;
            }
            else if (!hasError) {
                cpShift.calendarStatus = ECalendarStatus.issue;
            }
            cpShift.exceededMaxWorkingHours = error.errorType == RosterValidationErrorType.ExceededMaxWorkingHours;
            cpShift.missingSkills = error.errorType == RosterValidationErrorType.MissingSkills;
            cpShift.isOverTime = error.errorType == RosterValidationErrorType.OverTime;
            shiftErrorMessages.push(error.message)
        });

        if (errors?.length == 0)
            cpShift.calendarStatus = (cpShift.resourceId === '0') ? ECalendarStatus.scheduled : ECalendarStatus.assigned

        cpShift.errors = shiftErrorMessages
        return cpShift

    }


    const getShiftsBetweenDates = (startDate: Date, endDate: Date): IEvent[] => {
        if (endDate < startDate) return []; //this can happen temporarily when enddate is set before start date in code

        let shifts = shiftStore.filter(shift => {
            let withinInterval = false;

            withinInterval = isWithinInterval(
                new Date(shift.start),
                {
                    start: new Date(startDate),
                    end: new Date(endDate)
                })

            return withinInterval;
        });
        let leaves = getLeaves();
        return shifts.concat(leaves);
    }

    const getOtherSiteShiftsBetweenDates = (startDate: Date, endDate: Date): IOtherSiteEvent[] => {
        if (endDate < startDate) return [];
        return otherSiteShiftStore.filter(shift => {
            let withinInterval = false;

            withinInterval = isWithinInterval(
                new Date(shift.start),
                {
                    start: new Date(startDate),
                    end: new Date(endDate)
                })

            return withinInterval;
        })
    }

    const getShiftsForView = (): IEvent[] => {
        if (loader?.loading) {
            return []
        }
        return getShiftsBetweenDates(cStartDate, cEndDate)
    }

    const getOtherSiteShiftsForView = (): IOtherSiteEvent[] => {
        if (loader?.loading) {
            return []
        }
        return getOtherSiteShiftsBetweenDates(cStartDate, cEndDate)
    }

    // const getShift = (id: string) => {
    //     const shift = shiftStore.findIndex((shift) => shift.id === id)
    //     return shift > -1 ? shiftStore[shift] : undefined
    // }

    const getShift = useCallback((id: string) => {
        let shift = shiftStoreAccess[id];
        if (shift) return shift;
        return getLeaves().find(x => x.id == id);
    }, [shiftStoreAccess])

    const validateAndPublishShifts = async () => {

        const confirmation = await Swal.fire({
            title: 'Are you sure you want to publish the schedule?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.themePrimary,
            cancelButtonColor: theme.palette.tealLight,
            confirmButtonText: 'Publish'
        })
        if (confirmation.isConfirmed) {
            const status = await publish()

            if (status) {
                const finalShifts: { [key: string]: IEvent } = {};
                shiftStore.forEach((shift) => {
                    if (shift.bookableResourceStatus !== EBookingStatus.Scheduled || shift.changeStatus !== ChangeStatus.UnChanged) {
                        finalShifts[shift.id] = {
                            ...shift,
                            bookableResourceStatus: EBookingStatus.Scheduled,
                            changeStatus: ChangeStatus.UnChanged
                        }
                    }
                })
                setShiftStore({ update: finalShifts })
                toast("success", 'Schedule was published successfully');
            }
        }


    }

    const validateAllShifts = async () => {

        const cpShiftStore = shiftStore;
        let allShifts: IRosteringBookingViewModel[] = [];
        let originalShifts: { [key: string]: IEvent } = {};
        let newShiftStore: { [key: string]: IEvent } = {};

        cpShiftStore.forEach(shift => {
            let isUnassigned = shift.resourceId == null || shift.resourceId == '' || shift.resourceId == '0';
            if (isUnassigned && (shift.originalResourceID == null || shift.originalResourceID == '' || shift.originalResourceID == '0')) return;
            allShifts.push({
                id: shift.id,
                jobId: shift.id,
                tempId: '',
                start: shift.start,
                end: shift.end,
                bookableResourceId: isUnassigned ? shift.originalResourceID : shift.resourceId,
                cost: 0,
                status: isUnassigned ? ChangeStatus.Deleted : shift.changeStatus,
                bookingId: shift.BookingId
            })
            originalShifts[shift.id] = shift;
        });

        if (allShifts.filter(x => x.bookableResourceId != null).length == 0) {
            setValidated(true)
            return;
        }

        setLoading({ loading: true, message: 'Validating Shifts' });
        const response = (await post(`/Rostering/validate`, {
            shifts: allShifts,
            siteId: localStorage.getItem('siteid')
        })) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;
        if (!response.success) {
            if (response.errors && response.errors[0]) {
                await toast('error', response.errors[0]);
            }

            setLoading({ loading: false, message: '' });

        }
        response.body.forEach(returnedShift => {
            if (originalShifts[returnedShift.id]) {
                newShiftStore[returnedShift.id] = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
            } else {
                const newShift = populateCalendarStatusBasedOnServiceResponse(originalShifts[returnedShift.id], returnedShift.errors, originalShifts[returnedShift.id].resourceId);
                newShiftStore[newShift.id] = newShift;
            }

        });

        setLoading({ loading: false, message: '' });
        setShiftStore({ update: newShiftStore })
    }

    // Doesn't have a way of updating non-move/date changes (e.g. description)
    const publishSingleShift = async (shift?: IEvent): Promise<{ status: EPublishStatus, updatedEventDetails?: Partial<IEvent> }> => {
        if (!shift) {
            return { status: EPublishStatus.no_shift };
        }
        try {

            const confirmation = await Swal.fire({
                title: 'Are you sure you want to publish this shift?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: theme.palette.themePrimary,
                cancelButtonColor: theme.palette.tealLight,
                confirmButtonText: 'Publish'
            })

            if (!confirmation.isConfirmed) return { status: EPublishStatus.cancelled };

            const shiftData: IRosteringBookingViewModel = {
                id: shift.id,
                jobId: shift.id,
                tempId: '',
                start: shift.start,
                end: shift.end,
                bookableResourceId: shift.resourceId === '0' ? '' : shift.resourceId,
                cost: 0,
                status: ChangeStatus.Modified,
                bookingId: shift.BookingId,
            };

            const data: IPublishShiftsRequestViewModel = {
                shifts: [shiftData],
                saveShiftsWithWarning: true,
                siteId: localStorage.getItem('siteid') ?? '',
            };

            const response = await post(`/Rostering/publish`, data) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;


            if (response.success && response.body?.length > 0) {
                response.body.forEach(responseShift => {
                    const storedShift = shiftStore?.find(element => element.id === responseShift.id);
                    if (storedShift) {
                        populateCalendarStatusBasedOnServiceResponse(storedShift, responseShift.errors, storedShift.resourceId);
                    }
                });
                let errors = response.body.filter(b => b.errors?.length > 0).map(b => b.errors[0]);
                let errorMessages = errors.filter(e => e.message?.length > 0).map(e => e.message);

                toast('warning', 'Shift was not published. ' + errorMessages)

                return { status: EPublishStatus.publish_error };
            } else if (response.success) {
                const partialShift: IEvent = {
                    ...shift,
                    originalResourceID: shift.resourceId,
                    changeStatus: ChangeStatus.UnChanged
                }
                updateShiftInfo(shift.id, partialShift)
                toast('success', 'Shift was published successfully!')
                return { status: EPublishStatus.success, updatedEventDetails: partialShift };
            } else {
                toast('error', response.errors.length > 0 ? response.errors[0] : 'Shift was not published. There was an error whilst trying to publish')
                return { status: EPublishStatus.publish_error }
            }
        } catch (err) {
            console.error(err);
            toast('error', 'Shift not published: Network Error')
            return { status: EPublishStatus.network_error };
        }
    }


    // Doesn't have a way of updating non-move/date changes (e.g. description)
    //If not shifts provided, all shifts will be processed
    const publish = async (shifts?: IEvent[]): Promise<boolean> => {
        const prevDraftStatus = draftStatus;
        setDraftStatus(DraftStatus.publishing)

        if (shifts == null)
            shifts = shiftStore;

        const modifiedShifts: IRosteringBookingViewModel[] = [];
        shifts.forEach((shift) => {
            if (shift.changeStatus === ChangeStatus.Modified) {
                const shiftPublishData: IRosteringBookingViewModel = {
                    id: shift.id,
                    jobId: shift.id, //please provide job id and id the same
                    tempId: '', //for future usage. can be used for new shifts
                    start: shift.start,
                    end: shift.end,
                    bookableResourceId: shift.resourceId == '0' ? '' : shift.resourceId, //if unassigned set to empty string
                    cost: 0,
                    status: ChangeStatus.Modified, //For now, use 'modified' but in future, if we allow to add shifts from UI, then we can different status (like added, deleted et.) in here
                    bookingId: shift.BookingId
                }

                modifiedShifts.push(shiftPublishData);
            }
        })
        if (modifiedShifts.length < 1) {
            toast("warning", "No Shifts to Publish")
            setDraftStatus(prevDraftStatus)
            return false;
        }
        const response = (await post(`/Rostering/publish`, {
            shifts: modifiedShifts,
            saveShiftsWithWarning: true,
            siteId: localStorage.getItem('siteid')
        } as IPublishShiftsRequestViewModel)) as IServiceResponse<IBookableResourceRosterValidationResponse[]>;
        if (!response.success) {
            toast('error', response.errors[0]);
            setDraftStatus(prevDraftStatus)
            return false;
        }

        if (response.body.length > 0) {
            const updatedShifts: IEvent[] = [];
            response.body.forEach(rb => {
                const shift = shifts?.find(sh => sh.id === rb.id) as IEvent;
                let sf = populateCalendarStatusBasedOnServiceResponse(shift, rb.errors, shift.resourceId);
                updatedShifts.push(sf);
            });
            setShiftStore({ update: updatedShifts })
            toast("warning", 'Schedule was not published. There were issues during validation.');
            setDraftStatus(prevDraftStatus)
            return false;
        } else {
            deleteDraft()
            setHistory([])
            setDraftStatus(DraftStatus.empty)
            return true;
        }
    }

    const undoMove = () => {
        const cpHistory = [...history]
        if (cpHistory == null || cpHistory.length == 0) return;
        const editHistory = cpHistory.pop()
        if (!editHistory) return;
        const cpShiftStore = [...shiftStore]
        const index = cpShiftStore.findIndex((shift) => shift.id === editHistory.eventId)
        if (index > -1) {
            const shift = cpShiftStore[index];
            shift.resourceId = editHistory.fromResourceID;
            shift.changeStatus = shift.originalResourceID != shift.resourceId ? ChangeStatus.Modified : ChangeStatus.UnChanged;
            shift.displayCost = shift.changeStatus == ChangeStatus.UnChanged ? shift.actualCost : null;
            shift.calendarStatus = editHistory.calendarStatus
            setShiftStore({ update: [shift] })
        }
        setHistory(cpHistory)
    }

    const removeShift = (shiftId: string) => {
        setShiftStore({ delete: [shiftId] })
    }
    useEffect(() => {
        shiftStoreRef.current = shiftStore;
    }, [shiftStore])


    const deleteDraft = async () => {
        const storageKey = format(cStartDate, 'yyyy-MM-dd')
        localStorage.removeItem(storageKey)

        const start = format(cStartDate, 'yyyy-MM-dd');
        const end = format(cEndDate, 'yyyy-MM-dd');
        const draftKey = `${start}_${end}`;
        const existingDraftIds = draftId;
        let draftIds = existingDraftIds ? existingDraftIds.split(',') : [];
        if (draftIds.length > 0) {
            const account = getAccount();
            const siteId = getSiteId();

            const response = (await remove(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts?draftIds=${draftIds.join(',')}`)) as IServiceResponse<boolean>
            if (response.success)
                localStorage.removeItem(draftKey);
        }

        loadShifts()
        setDraftStatus(DraftStatus.empty)
    }

    const loadDraft = async () => {
        const start = format(cStartDate, 'yyyy-MM-dd');
        const end = format(cEndDate, 'yyyy-MM-dd');
        const siteId = getSiteId();
        const draftKey = `${start}_${end}_${siteId}`;

        const account = getAccount();
        const response = (await get(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts/${draftId}`)) as { history: IHistoryStore[], shiftStore: IEvent[] }
        setHistory(response.history);

        const cpShifts = [...shiftStore];
        const finalShifts: { [key: string]: IEvent } = {};

        response.shiftStore.forEach(shift => {
            finalShifts[shift.id] = {
                ...shift,
                start: new Date(shift.start),
                end: new Date(shift.end)
            }
        })

        cpShifts.forEach(shift => {
            const updatedShift = finalShifts[shift.id];
            if (!updatedShift) {
                finalShifts[shift.id] = {
                    ...shift,
                    resourceId: '0'
                }
            }
        })

        setShiftStore({ set: finalShifts })
    }

    const syncHistoryToState = (shifts: IEvent[] = shiftStore, funHistory: IHistoryStore[] = history) => {
        const cpShifts = [...shifts]
        return cpShifts.map((shift): IEvent => {
            const historyToProcess = funHistory.find((his) => (his.eventId === shift.id) && (shift.resourceId === "0"))
            const resourceId = historyToProcess ? historyToProcess.resourceId : shift.resourceId;
            return {
                ...shift,
                resourceId: resourceId,
                BookingId: shift.BookingId,
                calendarStatus: getCalendarStatus(shift),
                changeStatus: shift.originalResourceID != resourceId ? ChangeStatus.Modified : ChangeStatus.UnChanged

            } as IEvent;
        })
    }

    const reloadShifts = async () => {
        await loadShifts(true)
    }

    const saveDraft = async (): Promise<boolean> => {
        if (Array.isArray(history) && history.length > 0) {
            setDraftStatus(DraftStatus.synced)
            const account = getAccount();
            const siteId = getSiteId();
            const start = format(cStartDate, 'yyyy-MM-dd');
            const end = format(cEndDate, 'yyyy-MM-dd');
            const formData = new FormData();

            const data = {
                history,
                shiftStore
            }

            formData.append("file", JSON.stringify(data));
            formData.append("title", `${start}_${end}`); //TODO: Ask user for the name of the draft
            formData.append("start", start);
            formData.append("end", end);
            const response = (await post(`/Rostering/${account?.tenantId}/${account?.localAccountId}/${siteId}/drafts`, formData)) as IServiceResponse<IDraftSavedItem>
            if (response.success) {
                const draftKey = `${start}_${end}_${siteId}`;
                localStorage.setItem(draftKey, response.body.id);
                setDraftId(response.body.id)
            }
            return response?.success
        } else {
            toast('error', 'Unable to save draft as no changes have been made');
        }
        return false
    }

    const getShiftsInViewForResource = (startDate: Date, endDate: Date, resourceId?: string): IEvent[] => {
        if (endDate < startDate) return [];
        return shiftStore.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let withinInterval = false;
            withinInterval = isWithinInterval(
                new Date(shift.start),
                {
                    start: new Date(startDate),
                    end: new Date(endDate)
                })

            return foundResource && withinInterval
        })
    }

    const getOtherSiteShiftsForResource = (startDate: Date, endDate: Date, resourceId?: string): IOtherSiteEvent[] => {
        if (endDate < startDate) return [];
        return otherSiteShiftStore.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let withinInterval = false;
            withinInterval = isWithinInterval(
                new Date(shift.start),
                {
                    start: new Date(startDate),
                    end: new Date(endDate)
                })

            return foundResource && withinInterval
        })
    }

    const checkResourceForTimeClash = (resourceId: string, startDate: Date, endDate: Date) => {
        //take one mins on/off to allow shifts like 8am-4pm and then 4pm-8pm etc.
        startDate = addMinutes(startDate, 1);
        endDate = subMinutes(endDate, 1);
        let allEvents = shiftStore.map(x => x as IEventBase).concat(otherSiteShiftStore);
        return allEvents.filter(shift => {
            const foundResource = shift.resourceId === resourceId
            let within = false;
            within =
                isWithinInterval(startDate, { start: shift.start, end: shift.end })
                ||
                isWithinInterval(endDate, { start: shift.start, end: shift.end })
                ||
                isWithinInterval(shift.start, { start: startDate, end: endDate })
                ||
                isWithinInterval(shift.end, { start: startDate, end: endDate })

            return foundResource && within
        });


    }

    const checkShiftLocationIsValid = (startDate: Date, endDate: Date, eventId?: string, resourceId?: string, originalResourceId?: string): EEventStatus => {
        if (!resourceId || resourceId === '0' || resourceId === originalResourceId || !eventId) {
            return EEventStatus.valid
        }
        let hardErrorTypes = avantCareEnvInfo?.hardErrorTypes.map((et) => RosterValidationErrorType[et as keyof typeof RosterValidationErrorType])
        let isUnavailableHardError = hardErrorTypes?.indexOf(RosterValidationErrorType.Unavailable) != -1
        let isConflictShiftsHardError = hardErrorTypes?.indexOf(RosterValidationErrorType.ConflictBooking) != -1
        let isCharacteristicHardError = hardErrorTypes?.indexOf(RosterValidationErrorType.MissingSkills) != -1

        let leaves = getLeaves().filter(x => x.resourceId == resourceId)
            .filter(x => areIntervalsOverlapping({ start: startDate, end: endDate }, { start: x.start, end: x.end }));
        if (leaves.length > 0 && isUnavailableHardError)
            return EEventStatus.error;



        const resourcesShifts = checkResourceForTimeClash(resourceId, startDate, endDate);
        const bookableResource = getResource(resourceId);
        if ((!bookableResource || !bookableResource.availabilities) && isUnavailableHardError) {
            return EEventStatus.error
        }
        const isAvailable = bookableResource?.availabilities?.find(x => x.timeCode == 'Available' && x.start <= startDate && x.end >= endDate) != null
        if (!isAvailable && isUnavailableHardError) return EEventStatus.error;

        if (resourcesShifts?.length > 0 && isConflictShiftsHardError) {
            return EEventStatus.error;
        }
        let hasMetCharacteristics = checkRequiredCharacteristics(eventId, resourceId);
        return hasMetCharacteristics ? EEventStatus.valid : (isCharacteristicHardError ? EEventStatus.error : EEventStatus.characteristicsIssue);
    }

    const checkRequiredCharacteristics = (shiftId: string, resourceId: string): boolean => {
        const shift = getShift(shiftId);
        const resource = getResource(resourceId);
    
        if ((shift?.incidentType?.characteristic?.length ?? -1) === 0) {
            return true;
        }
    
        let characteristicsExpired = false;
    
        shift?.incidentType?.characteristic?.filter(incidentCharacteristic => incidentCharacteristic.status === statecode.Active).some(incidentCharacteristic => {
            let today = new Date();
    
            return resource?.characteristics?.some(resourceCharacteristic => {
                const isNameMatch = resourceCharacteristic?.characteristicId === incidentCharacteristic.id;
                var expiryDate = resourceCharacteristic.expiryDate == null ? new Date(0) : new Date(resourceCharacteristic.expiryDate);
                var shiftEndDate = shift.end == null ? new Date(0) : new Date(shift.end);    
                
                //resourceCharacteristic.expiryDate == null, means that never expires (AC product design, need to let clients know about this behavior)
                const isExpired = expiryDate < shiftEndDate;               

                if (isNameMatch && isExpired) {
                    characteristicsExpired = true;
                    return true; // Break out of the inner loop once an expired characteristic is found
                }
    
                return false;
            });
        });
    
        const characteristicsMet = !characteristicsExpired && !!shift?.incidentType?.characteristic?.filter(incidentCharacteristic => incidentCharacteristic?.status === statecode.Active).every(incidentCharacteristic =>
            resource?.characteristics?.find(resourceCharacteristic =>
                resourceCharacteristic.characteristicId === incidentCharacteristic.id
            )
        );
    
        return characteristicsMet;
    };

    const checkIsModified = (originalResourceID: string, resourceId: string) => {
        if (!originalResourceID || originalResourceID === "0") {
            if (resourceId === "0") {
                return ChangeStatus.UnChanged
            } else {
                return ChangeStatus.Modified
            }
        } else {
            if (originalResourceID === resourceId) {
                return ChangeStatus.UnChanged
            } else {
                return ChangeStatus.Modified
            }
        }
    }

    const getResourcesData = (resourceId: string) => {
        let resource = getResource(resourceId);
        const resourcesShifts = getShiftsInViewForResource(cStartDate, cEndDate, resourceId)
        const otherSiteResourceShifts = getOtherSiteShiftsForResource(cStartDate, cEndDate, resourceId)
        const shiftsCount = resourcesShifts.length
        const allSitesShiftsCount = resourcesShifts.length + otherSiteResourceShifts.length
        let hoursWorked = 0;
        let totalPay = 0;
        resourcesShifts.filter(shift=>shift.workOrderType?.msdyn_name?.toLowerCase() !== 'sleepover' && shift.workOrderType?.msdyn_name?.toLowerCase() !== 'on call').forEach((shift) => {
            hoursWorked = hoursWorked + differenceInMinutes(
                new Date(shift.end) ?? new Date(),
                new Date(shift.start) ?? new Date(),
            );
            if (shift.displayCost)
                totalPay += shift.displayCost;
        })
    
        let allSitesHoursWorked = hoursWorked;
        otherSiteResourceShifts.filter(shift=>shift.workOrderType?.msdyn_name?.toLowerCase() !== 'sleepover' && shift.workOrderType?.msdyn_name?.toLowerCase() !== 'on call').forEach((shift) => {
            allSitesHoursWorked = allSitesHoursWorked + differenceInMinutes(
                new Date(shift.end) ?? new Date(),
                new Date(shift.start) ?? new Date(),
            );
        });

        let payrollVariation = payrollHoursVariations?.find(x => x.bookableResourceId.toLocaleLowerCase() == resourceId.toLocaleLowerCase() && isSameDay(x.payrollStart, cStartDate) && isSameDay(x.payrollEnd, cEndDate))

        return {
            maxHoursAllowedPerPayroll: payrollVariation && payrollVariation.maximumHoursPerPayroll ? payrollVariation.maximumHoursPerPayroll : resource?.maximumWorkHourPerPayroll,
            maxShiftsAllowedPerPayroll: resource?.maximumWorkHourPerPayroll,
            maxHoursPerShift: resource?.maxHoursPerShift,
            hoursWorked: hoursWorked / 60,
            allSitesHoursWorked: allSitesHoursWorked / 60,
            shifts: shiftsCount,
            allSitesShifts: allSitesShiftsCount,
            requirements: [],
            totalPay,
            minimumWorkHoursPerPayroll: payrollVariation && payrollVariation.minimumHoursPerPayroll ? payrollVariation.minimumHoursPerPayroll : resource?.minimumWorkHoursPerPayroll,
        } as IResourceData
    }
    const getResourceShifts = (resourceId: string) => {
        return shiftStore.filter(x => x.resourceId === resourceId);
    }

    const setCalendarDates = (startDate: Date, type: TView) => {
        let endDate: Date
        if (type === "resourceTimelineFortnight") {
            endDate = new Date(startDate.getTime())
            endDate.setDate(endDate.getDate() + 13)
            endDate = endOfDay(endDate);
        } else {
            endDate = new Date(startDate.getTime())
            endDate.setDate(endDate.getDate() + 6)
            endDate = endOfDay(endDate);
        }
        if (isEqual(cStartDate, startDate) && isEqual(cEndDate, endDate)) {
            console.error("No Update Required")
            return
        }
        setCEndDate(endDate)
        setCStartDate(startDate)
        loadShifts(true, startDate, endDate).then(() => {
            setHistory([])
        });




    }

    const updateShiftInfo = (shiftId: string, data: Partial<IEvent>) => {
        const cpShifts = [...shiftStore]
        const updatedShiftIndex = cpShifts.findIndex(shift => shift.id === shiftId)

        if (updatedShiftIndex < 0) {
            return
        }

        setShiftStore({ update: [{ ...cpShifts[updatedShiftIndex], ...data, loading: false }] })

        return cpShifts[updatedShiftIndex];
    }

    const defineUserAvailability = (startDate: Date, endDate: Date, cResourceId: string, eventId: string): IAvailability[] => {
        const resources = getAllResources()
        return resources.map((resource) => {
            if (cResourceId === resource.id) {
                if (resource.availabilities) {
                    const available = resource.availabilities?.find(x => x.timeCode == 'Available' && x.start >= startDate && endDate <= x.end);
                    return { resourceId: resource.id, availability: available ? EEventStatus.valid : EEventStatus.error }
                }

            }
            const availability = checkShiftLocationIsValid(startDate, endDate, eventId, resource.id);
            return { resourceId: resource.id, availability }
        })

    }

    const callbackShifts = useCallback(() => {
        return getShiftsForView()
    }, [shiftStore, getShiftsForView, cEndDate, cStartDate, draftStatus, loader])

    const memoCallbackShifts = useMemo(callbackShifts, [callbackShifts])

    const callbackOtherSiteShifts = useCallback(() => {
        return getOtherSiteShiftsForView()
    }, [otherSiteShiftStore, getOtherSiteShiftsForView, cEndDate, cStartDate, loader])

    const memoCallbackOtherSiteShifts = useMemo(callbackOtherSiteShifts, [callbackOtherSiteShifts])

    const calculateCost = async () => {
        setLoading({ loading: true, message: 'Calculating cost...' });
        const updatedShifts: IEvent[] = [];
        const shifts = shiftStore.filter(x => x.resourceId != '0' && x.BookingId != '').map<Partial<IRosteringBookingViewModel>>(x => {
            return { bookableResourceId: x.resourceId, end: x.end, start: x.start, id: x.id, }
        });
        const calculatedShiftResponse: IServiceResponse<IRosteringBookingViewModel[]> = await post('/Rostering/calclatecosts', shifts);
        if (!calculatedShiftResponse.success) {
            //TODO: error handling;
            if (loader.message === 'Calculating cost...') {
                setLoading({ loading: false, message: '' });
            }
            return;
        }
        const calculatedShifts = calculatedShiftResponse.body;
        shiftStore.forEach((s) => {
            const cs = calculatedShifts.find(x => x.id == s.id);
            if (cs) {
                updatedShifts.push({
                    ...s,
                    actualCost: cs.cost,
                    displayCost: cs.cost
                })
            }
        });

        setShiftStore({ update: updatedShifts })

        if (loader.message === 'Calculating cost...') {
            setLoading({ loading: false, message: '' });
        }
        return true;
    };

    const checkIfShiftAllowed = async (event: IEvent) => {
        if (!event.bidders) {
            return
        }
        const bidders: IBookableResourceBooking[] = []

        for (const bidder of event.bidders) {
            const assignedShifts = getResourceShifts(bidder.bookableResourceId);
            const response = (await post(`/Rostering/bookableresource/${bidder.bookableResourceId}/isshiftAllowed?jobId=${event?.id}`, assignedShifts)) as IServiceResponse<IRosterValidationResponse>;
            if (response.success && response.body.status == 'Failed') {
                toast('error', response.body.errors[0].message);
                continue;
            }
            const errors = response.body.errors
            errors.forEach((error) => {
                switch (error.errorType) {
                    case RosterValidationErrorType.OverTime: {
                        bidder.isOverTime = true
                        break
                    }
                    case RosterValidationErrorType.MissingSkills: {
                        bidder.missingSkills = true
                        break
                    }
                    case RosterValidationErrorType.Unavailable: {
                        bidder.unavailable = true
                        break
                    }
                    case RosterValidationErrorType.ExceededMaxWorkingHours: {
                        bidder.exceededMaxWorkingHours = true
                    }
                }
            })
            bidders.push(bidder);
        }

        updateShiftInfo(event.id, { bidders })
    }
    const publishShift = async (shiftId: string) => {
        let event = shiftStore.find(x => x.id == shiftId) as IEvent;


        const confirmation = await Swal.fire({
            title: 'Are you sure you want to publish the shift?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.themePrimary,
            cancelButtonColor: theme.palette.tealLight,
            confirmButtonText: 'Publish shift'
        })
        if (confirmation.isConfirmed) {
            let success = await publish([event]);
            if (!success) return;
            event.bookableResourceStatus = EBookingStatus.Scheduled;
            event.changeStatus = ChangeStatus.UnChanged;
            updateShiftInfo(shiftId, event);
        }

    }

    const approveRejectCancellationRequest = async (event: IEvent, approved: boolean, comments: string): Promise<boolean> => {

        var bookableResourceData: any = {
            bookableresourcebookingid: event.BookingId,
            illumina_cancellationresponsecomments: comments,
            illumina_cancellationrequeststatus: approved ? ShiftCancellationRequestStatus.Approved : ShiftCancellationRequestStatus.Declined,
            illumina_cancellationresponseresolvedon: null
        };
        if (approved) {
            var bookingStatusList = await getBookingStatusList();
            let cancelStatus = bookingStatusList.find(x => x.name.toLocaleLowerCase() == 'canceled')
            bookableResourceData['BookingStatus@odata.bind'] = `/bookingstatuses(${cancelStatus?.bookingstatusid})`;

        }
        let r = await patch('/Entity/bookableresourcebookings/' + event.BookingId, bookableResourceData);
        if (!r?.success)
            return false;

        return true;
    }

    const patchShift = async (shiftId: string, shiftData: Partial<IEvent>) => {
        var workOrderData = {};
        var bookableResourceData = {};
        Object.entries(shiftData).forEach(keyPair => {
            if (!(Object.keys(EEventToDBMap).includes(keyPair[0]))) return;

            if (keyPair[0] === 'bookableResourceStatus') {
                bookableResourceData = {
                    ...bookableResourceData,
                    [EEventToDBMap[keyPair[0] as 'bookableResourceStatus']]: `/bookingstatuses(${keyPair[1]})`,
                    duration: shiftData.shiftLength,
                    starttime: shiftData.start,
                    endtime: shiftData.end
                }

                return;
            }

            workOrderData = {
                ...workOrderData,
                [EEventToDBMap[keyPair[0] as 'isJobBidding' | 'start' | 'end' | 'description' | 'shiftLength']]: keyPair[1],
                msdyn_timefrompromised: shiftData.start,
                msdyn_timetopromised: shiftData.end
            }
        })

        var workOrderResponse;
        var bookableResourceResponse;

        if (Object.entries(workOrderData).length) {
            workOrderResponse = patch('/Entity/msdyn_workorders/' + shiftId, workOrderData);
        }

        if (Object.entries(bookableResourceData).length) {
            const shift = shiftStore.find(event => event.id === shiftId)

            bookableResourceResponse = patch('/Entity/bookableresourcebookings/' + shift?.BookingId, bookableResourceData);

            const cpShifts = [...shiftStore];
            const shiftIndex = cpShifts.findIndex((event) => event.id === shiftId)
            setShiftStore({ delete: [shiftId] })
        }

        await workOrderResponse;
        await bookableResourceResponse;

        reloadShifts();
    }


    const createLateClientCancelTimeEntry = async (shift: IEvent) => {
        let jobType = JobType[shift.jobType.replace(/ /g, '') as keyof typeof JobType];
        if (jobType != JobType.ResidentialDisabilityandAgedCareService) return;

        let workOrderType = shift.workOrderType.msdyn_name.replace(/ /g, '');
        let timeEntryType = ETimesheetEntryType.Work;

        switch (workOrderType) {
            case EWorkWorderType[0]: //Active
                timeEntryType = ETimesheetEntryType.Work;
                break;

            case EWorkWorderType[1]: // On Call
                timeEntryType = ETimesheetEntryType.OnCallActive;
                break;

            case EWorkWorderType[2]: // Sleepover
                timeEntryType = ETimesheetEntryType.SleepoverActive;
                break;

                Default:
                timeEntryType = ETimesheetEntryType.Work

        }

        let timeEntry: ITimeEntry = {
            msdyn_entrystatus: ETimesheetEntryStatus.Draft,
            msdyn_type: timeEntryType,
            msdyn_start: shift.start,
            msdyn_end: shift.end,
            msdyn_description: "Shift " + shift.title + " Late Cancelled by Client",
            msdyn_duration: shift.shiftLength / 60,
            illumina_netduration: shift.shiftLength / 60,
            "msdyn_bookableresource@odata.bind": "/bookableresources(" + shift.resourceId + ")",
            //"msdyn_BookableResourceBooking@odata.bind": "/bookableresourcebookings(" + shift.BookingId + ")", //booking is already cancelled, so no need to associate the booking
            "msdyn_WorkOrder@odata.bind": "/msdyn_workorders(" + shift.id + ")"
        }

        console.log(timeEntry);
        await post('/Entity/msdyn_timeentries', timeEntry);
    }

    const toggleShowOtherSiteShifts = useCallback(() => {
        setShowOtherSiteShifts(!showOtherSiteShifts)
    }, [showOtherSiteShifts, setShowOtherSiteShifts])

    return (
        <shiftsContext.Provider value={{
            saveDraft,
            startDate: cStartDate,
            endDate: cEndDate,
            loader,
            moveShift,
            getShiftsForCalendarView: () => memoCallbackShifts,
            getOtherSiteShiftsForCalendarView: () => memoCallbackOtherSiteShifts,
            getResourceData: getResourcesData,
            checkShiftLocationIsValid,
            updateShiftInfo,
            getShift,
            draftStatus,
            setCalendarDates,
            undoShiftMove: undoMove,
            validateAndPublishShifts: validateAndPublishShifts,
            deleteDraft,
            loadDraft,
            reloadShifts,
            checkRequiredCharacteristics,
            defineUserAvailability,
            checkIsModified,
            calculateCost,
            getResourceShifts,
            getEnvironmentInfo,
            checkIfShiftAllowed,
            publishShift,
            publishSingleShift,
            patchShift,
            createLateClientCancelTimeEntry,
            showOtherSiteShifts,
            toggleShowOtherSiteShifts,
            view,
            setView,
            loadingPayrollData,
            siteLocation,
            validated,
            getShiftTypes,
            approveRejectCancellationRequest,
            getBookingStatusList,
            isCancellationReviewPending,
            validateAllShifts,
            removeShift: removeShift

        }}>
            {props.children}
        </shiftsContext.Provider>
    )

}

// function getRandomInt(max: number) {
//     return Math.floor(Math.random() * max);
// }

export { ShiftsProvider, shiftsContext }
